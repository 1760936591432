import React, {useState, useRef, useEffect} from 'react'
import Layout from '../../layout/Layout'
import FaqCard from '../../components/faqPage/FaqCard'
import Card from '../../components/Card/Card'
import { graphql, useStaticQuery } from 'gatsby'
import { gsap, Power3} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import * as styles from './faq.module.scss'

gsap.registerPlugin(ScrollTrigger)

export default function Faq() {

    let faqPage = useRef(null)

    useEffect( ()=>{
      gsap.from(faqPage,{
        scrollTrigger:{
            trigger: faqPage,
            start: 'top 90%',
            end: 'bottom 70%',
            toggleActions: 'play none none none',
        },
        opacity: 0,
        y:100,
        duration:1.5,
        ease:Power3.easeInOut,
    })
  },[])

    // pull data from contentful
    const { headerTitle, metaTitle, faqs } = useStaticQuery(getFaqPage).contentfulFaqPage
    
    // init searchTerm state
    const [ searchTerm , setSearchTerm ] =  useState("")

    // cb for get searchTerm from searchBar component
    const updateSearchTerm = ( searchTerm ) => {
        setSearchTerm( searchTerm )
    }

    // update content by searchTerm
    const filteredFaqs = faqs.filter( faq => {
        return faq.question.toLowerCase().includes(searchTerm.toLowerCase())
    })

    return (
        <>
            <Layout 
                title = { headerTitle || "FAQ" } 
				metaTitle = { metaTitle }
                faqHero
                updateSearchTerm = {updateSearchTerm}>

                <section className = {styles.faqPage} 
                         ref = { el => { faqPage = el }}>

                    <div className = {styles.cardWrapper}>
                        <Card />
                    </div>

                    <div className = {styles.FaqCardWrapper}>

                        {
                            searchTerm === "" 
                            ? null 
                            : filteredFaqs.length !== 0 
                            ? null 
                            : <p> Sorry, no faqs to show for <strong> {searchTerm} </strong> </p>
                        }

                        {
                            filteredFaqs.map( ( faq, index ) => {  
                                return(
                                <FaqCard 
                                    key = {faq.contentful_id}
                                    checked = { index === 0 ? true : false } 
                                    {...faq}
                                    />)
                                }
                            )
                        }

                    </div>

                </section>

            </Layout>
        </>
      )
}


const getFaqPage = graphql`
query faqPage {
    contentfulFaqPage {
      headerTitle
	  metaTitle
      faqs {
        question
        answer {
          answer
        }
        contentful_id
      }
    }
  }
  

`