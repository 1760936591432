import React, { useState } from 'react'
import * as styles from './faqCard.module.scss'

export default function FaqCard( props ) {

    const {question , answer } = props
    const [isChecked, setIsChecked ] = useState( props.checked ? true : false )
    return (
        <div className = {styles.container}>
            <input type = "checkBox"
                   checked = {isChecked}
                   onChange = { () => {
                       setIsChecked(!isChecked)
                   } }
                    />

            <div className = {styles.questionCover}></div>

            <div className = {styles.question}>
                <p> { question || "This is the Question here"} </p>
                <div className = {styles.img}></div>
            </div>

            <div className = {styles.answer}>

                {
                    answer 
                    ? 
                    
                    answer.answer
                    :
                    null
                }  

            </div>
        </div>
    )
}

