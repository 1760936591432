import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import iconArrow from '../../assets/icon/arrow.svg'
import * as styles from './card.module.scss'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

export default function Card() {

    const {title, links} = useStaticQuery(getCard).contentfulCard

    return (
        <div className = {styles.container}>
            <h2 className = {styles.title}> {title} </h2>       

            {links.map( ({slug, title, isExternal, url}) => {
                
                return(
                isExternal 
                ?
                <OutboundLink href = {url} key = {url}> {title} <img src = {iconArrow} alt = "" /> </OutboundLink>
                :
                <Link to = {slug} key = {slug} > {title}  <img src = {iconArrow} alt = ""/> </Link>
                )
            })}    
        </div>
    )
}

const getCard = graphql`
    query cardQuery {
        contentfulCard {
        title
        links {
            slug
            title
            isExternal
        }
        }
    }
    
`